<template>
  <div class="w-full self-start bg-white lg:bg-transparent py-4">
    <div
      class="font-main h-full md:h-14 text-left flex flex-col md:flex-row items-center w-full max-w-md md:pt-0 bg-white lg:bg-transparent"
      @pointerenter="toggleMenuOpen"
      @pointerleave="toggleMenuClosed"
    >
      <div class="flex w-full items-center px-4 lg:px-[24px]">
        <div
          v-if="env.STORE_FEATURES.IN_STORE_PICKUP"
          class="flex-shrink-0 pr-3 mr-2 border-r border-gray-200 md:border-gray-500"
        >
          <PickupDeliveryToggle
            :options="acquisitionLabels"
            :selected="menuToggleState"
            :class="{ 's-toggle--dark': context === 'top' }"
            @select-acquisition-option=";[selectAcquisitionOption($event), toggleMenuOpen($event)]"
          />
        </div>
        <button
          type="button"
          class="s-select flex items-center font-alt text-black lg:text-white text-sm cursor-pointer ml-2 md:ml-1 pr-8 py-2 bg-no-repeat whitespace-nowrap overflow-auto"
          :class="{ 's-select--open': menuVisible }"
          @click="isMenuVisible"
        >
          <span class="overflow-hidden overflow-ellipsis">
            <template v-if="!xSelectedAddress && !xSelectedPickupLocation">
              <template v-if="menuDeliverySelected"
                ><span class="md:hidden">+ Address</span
                ><span class="hidden md:inline">+ Add your Address</span></template
              >
              <template v-if="menuPickupSelected"
                ><span class="md:hidden">Location</span
                ><span class="hidden md:inline">Choose a Location</span></template
              >
            </template>
            <template v-else>
              <template v-if="xDeliverySelected">{{ _address(xSelectedAddress) }}</template>
              <template v-if="xPickupSelected">{{ xSelectedPickupLocation?.name }}</template>
            </template>
          </span>
        </button>
      </div>

      <div
        v-if="menuVisible"
        class="location-height border-t-2 bordert-t-gray lg:border-none mt-5 s-places-menu !h-full !w-full bg-white left-0 inline-flex p-[24px] flex-col items-start gap-4 overflow-y-auto md:w-auto md:h-auto md:top-14 md:fixed md:z-1000 md:shadow-xl max-w-[430px] sm:mt-4 lg:mt-0"
      >
        <PlaceList
          :listing-type="menuDeliverySelected ? 'delivery' : 'pickup'"
          :places="availablePlaces"
          :selected-place="selectedPlace"
          @shop-place="shopPlace"
        >
          <template #header> </template>
          <template #footer>
            <p
              v-if="menuDeliverySelected && !xSelectedAddress"
              class="text-sm bg-gray-100 border rounded-md px-4 py-6 my-3 mb-6"
            >
              You don't have any addresses yet.
            </p>
            <div
              v-if="menuDeliverySelected"
              class="flex gap-x-3 mt-3 text-md font-medium flex p-6 items-start gap-4 border-2 rounded bg-white p-[24px] border-gray-200 cursor-pointer"
            >
              <NuxtLink
                v-if="xIsAuthenticated"
                class="border-r pr-3 inline-flex gap-x-2 hover:text-teal font-medium underline"
                to="/profile/address"
              >
                See All
                <IconArrowRight width="5" />
              </NuxtLink>
              <button
                type="button"
                class="hover:text-teal font-medium underline flex items-center"
                @click="openDeliveryModal"
              >
                <NewIconPlus
                  width="20"
                  class="mr-4"
                />
                <span>Add a New Address</span>
              </button>
            </div>
          </template>
        </PlaceList>
      </div>
    </div>
  </div>
</template>

<script setup>
  import env from '@/environment'
  import NewIconPlus from '~/components/icon/NewIconPlus.vue'
</script>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import LocationSelectionMixin from '@/mixins/LocationSelection'
  import _address from '@/utils/filters/address'
  import { LANG_NO_LOCATIONS_WITH_PICKUP } from '@/utils/lang'

  export default {
    name: 'NavbarSelectLocation',

    mixins: [LocationSelectionMixin],
    salve: {
      lang: {
        noLocationsWithPickup: LANG_NO_LOCATIONS_WITH_PICKUP
      }
    },
    props: {
      context: {
        type: String,
        default: 'top'
      },
      menuOpenProp: {
        type: Boolean,
        default: false
      }
    },
    emits: ['open', 'close', 'openPlacesMenu', 'closePlacesMenu'],
    data() {
      return {
        menuOpen: false
      }
    },
    computed: {
      ...mapGetters('user-region', ['xSelectedAddress']),
      ...mapGetters('authuser', ['xIsAuthenticated']),

      menuVisible() {
        return this.menuOpen
      }
    },
    watch: {
      menuOpenProp(value) {
        this.menuOpen = value
      }
    },
    methods: {
      ...mapActions(['xToggleMobileNavbar', 'xCloseMobileNavbar']),
      _address,

      shopPlace(location, event) {
        this.selectPlace(location)
        this.updateUrlWithLocation(location)
      },

      updateUrlWithLocation(location) {
        const currentUrl = new URL(window.location.href)
        const params = new URLSearchParams(currentUrl.search)
        let loc = ''

        if (location.location_id) {
          loc = location.location_id
        } else if (location.id) {
          loc = location.id
        }

        // Update or add the location_id parameter
        params.set('location_id', loc)

        // Construct the new URL
        const newUrl = `${currentUrl.pathname}?${params.toString()}`

        // Use Vue Router to update the URL without reloading the page
        this.$router.push(
          newUrl,
          () => {},
          () => {}
        )
      },

      clickMenu(event) {
        if (this.xDeliverySelected && !this.xSelectedAddress) {
          this.openDeliveryModal()
          return
        }
        if (event?.pointerType !== 'touch') {
          return
        }
        this.toggleMenu(true) // Only open if it’s closed
      },

      openDeliveryModal() {
        this.menuOpen = false
        this.$emit('closePlacesMenu')
        this.$emit('close')
        this.$emit('open', 'delivery')
      },

      toggleMenu(state = null) {
        this.menuOpen = state !== null ? state : !this.menuOpen
        this.$emit(this.menuOpen ? 'openPlacesMenu' : 'closePlacesMenu')
      },

      toggleMenuOpen(event) {
        if (event?.pointerType === 'touch') return
        if (!this.menuOpen) this.toggleMenu(true)
      },

      toggleMenuClosed(event) {
        if (event?.pointerType === 'touch') return
        if (this.menuOpen) this.toggleMenu(false)
      },
      toggleMobileNavbar() {
        this.xCloseMobileNavbar()
        this.toggleMenuClosed()
      },
      isMenuVisible() {
        if (this.menuOpen) {
          // If the menu is open, close it
          this.toggleMenu(false)
        } else {
          // If the menu is closed, open it
          this.toggleMenu(true)
        }
      }
    }
  }
</script>

<style lang="postcss" scoped>
  .s-places-menu {
    @screen md {
      max-height: 94vh;
    }
  }
  .s-select {
    background-image: url('assets/icon/svg/chevron.svg');
    @screen md {
      background-image: url('assets/icon/svg/chevron-white.svg');
    }

    background-position: calc(100% - theme('spacing.3')) center;
    background-size: theme('spacing.3');
  }
  .s-select--open {
    background-image: url('assets/icon/svg/chevron-up.svg');
    @screen md {
      background-image: url('assets/icon/svg/chevron-white-up.svg');
    }
  }
</style>
